<template>
  <div class="page">
    <el-dialog
      title="修改帖子"
      :visible.sync="myShowDialog"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form>
        <el-form-item
          v-if="newsInfo !== null"
          label="点赞数"
          :label-width="formLabelWidth"
        >
          <el-input v-model="likeNum" placeholder="是否推荐"></el-input>
        </el-form-item>
        <el-form-item
          v-if="newsInfo !== null"
          label="阅读数"
          :label-width="formLabelWidth"
        >
          <el-input v-model="lookNum" placeholder="是否推荐"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="canncelClick">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { editPost } from "@/http/api";

export default {
  data() {
    return {
      myShowDialog: false,
      formLabelWidth: "120px",
      likeNum: "",
      lookNum: "",
    };
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    // 当这个不为null时，表示编辑
    postInfo: {
      type: Object,
    },
  },
  watch: {
    postInfo(newVal, oldVal) {
      if (newVal !== null && newVal !== undefined) {
        this.likeNum = newVal.likeNumber;
        this.lookNum = newVal.lookNum;
      }
    },
    showDialog(newValue) {
      this.myShowDialog = newValue;
    },
  },
  computed: {},
  methods: {
    addClick() {
      editPost({
        id: this.postInfo.id,
        likeNumber: this.likeNum,
        lookNum: this.lookNum,
      }).then((res) => {
        this.$emit("refreshList");
        this.canncelClick();
        this.$emit("updateNewsInfo");
        this.$message.success("修改成功");
      });
    },
    canncelClick() {
      this.likeNum = "";
      this.lookNum = "";
      this.$emit("updateNewsInfo");
      this.$emit("update:showDialog", false);
    },
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px !important;
  text-align: center;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
}
</style>
