<template>
    <div class="user-list">
        <div class="tools-div">
            <el-input class="search-input" v-model="keyword" placeholder="请输入用户名称"></el-input>
            <el-button type="primary" icon="el-icon-search" @click="getUserList">搜索</el-button>
        </div>
        <div class="tab">
            <el-table :data="userList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center">
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="150" align="center">
                </el-table-column>
                <el-table-column label="头像" width="100" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatar" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column prop="openid" label="openid" width="180" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="220" align="center">
                </el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="220" align="center">
                </el-table-column>
                <el-table-column label="否能发言" width="100" align="center">
                    <template slot-scope="scope">
                        <!-- <p>{{ scope.row.status === 0 ? '禁用' : '正常' }}</p> -->
                        <el-switch :value="switchValue(scope.row.status)" active-color="#13ce66"
                            inactive-color="#ff4949" @change="changeStatus(scope.row, scope.$index)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="是否管理员" width="100" align="center">
                    <template slot-scope="scope">
                        <!-- <p>{{ scope.row.status === 0 ? '禁用' : '正常' }}</p> -->
                        <el-switch :value="switchAdminValue(scope.row.isAdmin)" active-color="#13ce66"
                            inactive-color="#ff4949" @change="changeAdmin(scope.row, scope.$index)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination background layout="prev, pager, next" :page-count="totalPage" @current-change="nextPgae">
        </el-pagination>
        <EditUserInfoDialog :showDialog.sync="showDialog" @cancelEditDialog="cancelEditDialog"
            @refreshList="getUserList" :userInfo="userInfo" @updateTabbarInfo="updateTabbarInfo" />
    </div>
</template>
<script>
import { searchUser, editUser } from '@/http/api'
import EditUserInfoDialog from '@/components/dialogs/EditUserInfoDialog'
export default {
    name: 'UserList',
    components: {
        EditUserInfoDialog
    },
    data() {
        return {
            userList: [],
            keyword: '',
            pageSize: 10,
            pageIndex: 1,
            totalPage: 1,
            showDialog: false,
            userInfo: null,

        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getUserList(); // 调用接口请求
            }
        },
    },
    created() {
        if (this.schoolid !== '') {
            this.getUserList();
        }
    },
    methods: {
        cancelEditDialog() {
            this.showDialog = false;
        },
        updateTabbarInfo() {
            this.userInfo = null
        },
        cancelAddDialog() {
            this.showDialog = false;
        },
        switchValue(status) {
            return status !== 0;
        },
        switchAdminValue(status) {
            return status !== 0;
        },
        getUserList() {
            searchUser({
                schoolid: this.schoolid,
                keyword: this.keyword,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            }).then(res => {
                this.userList = res.data.rows
                this.totalPage = res.data.totalPage
            })
        },
        changeStatus(item, index) {
            let status = item.status === 0 ? 1 : 0
            // 修改数据
            editUser({
                id: item.id,
                status: status
            }).then(res => {
                this.userList[index].status = status
            })
        },
        changeAdmin(item, index) {
            let isAdmin = item.isAdmin === 0 ? 1 : 0
            // 修改数据
            editUser({
                id: item.id,
                isAdmin: isAdmin
            }).then(res => {
                this.userList[index].isAdmin = isAdmin
            })
        },
        nextPgae(e) {
            this.pageIndex = e;
            this.getUserList();
        },
        handleEdit(index, item) {
            this.showDialog = true
            this.userInfo = item
        },
    }
}
</script>
<style lang="scss">
.user-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>