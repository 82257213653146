<template>
    <div class="tabbar-list">
        <div class="tools-div">
            <el-button type="primary" @click="showDialog = true">添加</el-button>
        </div>
        <div class="tab">
            <el-table :data="dataList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="title" label="标题" width="180" align="center"></el-table-column>
                <el-table-column label="图标" width="180" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.icon" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column prop="router" label="路由" width="180" align="center"></el-table-column>
                <el-table-column prop="sort" label="排序" width="180" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="220" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="220" align="center"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <AddTabbarDialog :schoolid=schoolid :showDialog.sync="showDialog" @cancelAddDialog="cancelAddDialog"
            @refreshList="getDatas" :tabbarInfo="tabbarInfo" @updateTabbarInfo="updateTabbarInfo" />
    </div>
</template>
<script>
import { getTabbarList, removeTabbar } from '@/http/api'
import AddTabbarDialog from '@/components/dialogs/AddTabbarDialog'
export default {
    name: 'TabbarList',
    components: {
        AddTabbarDialog
    },
    data() {
        return {
            dataList: [],
            showDialog: false,
            tabbarInfo: null,
        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDatas(); // 调用接口请求
            }
        },
    },
    created() {
        if (this.schoolid !== '') {
            this.getDatas();
        }
    },
    methods: {
        getDatas() {
            getTabbarList({
                schoolid: this.schoolid,
            }).then(res => {
                this.dataList = res.data
            })
        },
        cancelAddDialog() {
            this.showDialog = false;
        },
        handleDelete(index, item) {
            removeTabbar({
                id: item.id
            }).then(res => {
                this.dataList.splice(index, 1);
                this.$message.success('删除成功')
            })
        },
        handleEdit(index, item) {
            this.showDialog = true
            this.tabbarInfo = item
        },
        updateTabbarInfo() {
            this.tabbarInfo = null
        }
    }
}
</script>
<style lang="scss">
.tabbar-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>