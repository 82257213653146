<template>
    <div class="post-list">
        <div class="tab">
            <el-table :data="dataList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="name" label="发帖人" width="150" align="center"></el-table-column>
                <el-table-column label="头像" width="100" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatar" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column prop="postTypeTitle" label="帖子类型" width="100" align="center"></el-table-column>
                <el-table-column label="类型" width="100" align="center">
                    <template slot-scope="scope">
                        <p style="color: #409EFF;">{{ scope.row.type === 1 ? '图文' : scope.row.type === 2 ? '公众号' : '短视频'
                            }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="帖子内容" width="180" align="center"></el-table-column>
                <el-table-column label="资源" width="100" align="center">
                    <template slot-scope="scope">
                        <p v-if="scope.row.assets.length > 0" style="color: #409EFF;" @click="lookClick(scope.row)">点击查看
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="是否匿名发帖" width="100" align="center">
                    <template slot-scope="scope">
                        <p style="color: #409EFF;">{{ scope.row.isAnonymityPost === 1 ? '是' : '否' }}</p>
                    </template>
                </el-table-column>

                <el-table-column label="是否允许匿名评论" width="100" align="center">
                    <template slot-scope="scope">
                        <p style="color: #409EFF;">{{ scope.row.isAnonymityReview === 1 ? '是' : '否' }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="180" align="center"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">通过</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">不通过</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination background layout="prev, pager, next" :page-count="totalPage" @current-change="nextPgae">
        </el-pagination>
    </div>
</template>
<script>
import { getAdminPostList, editPost } from '@/http/api'
export default {
    name: 'PostList',
    components: {

    },
    data() {
        return {
            dataList: [],
            pageSize: 10,
            pageIndex: 1,
            totalPage: 1
        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDatas(); // 调用接口请求
            }
        },
    },
    created() {

    },
    created() {
        if (this.schoolid !== '') {
            this.getDatas();
        }
    },
    methods: {
        getDatas() {
            getAdminPostList({
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                schoolid: this.schoolid,
                status: 0
            }).then(res => {
                this.totalPage = res.data.totalPage
                this.dataList = res.data.rows
            })
        },
        nextPgae(e) {
            this.pageIndex = e;
            this.getDatas();
        },
        lookClick(item) {
            if (item.url !== null && item.url !== '' && item.url !== undefined) {
                window.open(item.url, '_blank')
            } else {
                this.lookShowDialog = true;
                this.lookNewsInfo = item;
            }

            if (item.type === 1) {
                // 图片
                this.$hevueImgPreview({
                    multiple: true,
                    nowImgIndex: 0,
                    imgList: item.assets
                })
            } else if (item.type === 2) {
                // 公众号
                window.open(item.assets[0], '_blank')
            } else {
                // 视频
                window.open(item.assets[0], '_blank')
            }
        },
        editPostById(map, index) {
            editPost(map).then(res => {
                if (res.code === 200) {
                    this.dataList.splice(index, 1);
                    this.$message.success('操作成功')
                }
            })
        },
        handleDelete(index, item) {
            this.editPostById({ id: item.id, status: 2 }, index)
        },
        handleEdit(index, item) {
            this.editPostById({ id: item.id, status: 1 }, index)

        },
    }
}
</script>
<style lang="scss">
.post-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>