<template>
    <div class="school-list">
        <div class="tools-div">
            <el-button type="primary" @click="showDialog = true">添加</el-button>
        </div>
        <div class="tab">
            <el-table :data="dataList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="name" label="学校名称" width="150" align="center"></el-table-column>
                <el-table-column label="图标" width="100" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.image" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="180" align="center"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <AddSchoolDialog @refreshList="getDatas" :showDialog.sync="showDialog" :schoolInfo="schoolInfo"
            @updateSchoolInfo="updateSchoolInfo" />
    </div>
</template>
<script>
import { getSchoolList } from '@/http/api'
import AddSchoolDialog from '@/components/dialogs/AddSchoolDialog'
export default {
    name: 'SchoolList',
    components: {
        AddSchoolDialog
    },
    data() {
        return {
            dataList: [],
            showDialog: false,
            schoolInfo: null,
        }
    },
    created() {
        this.getDatas();
    },
    methods: {
        getDatas() {
            getSchoolList().then(res => {
                this.dataList = res.data
            })
        },
        cancelAddDialog() {
            this.showDialog = false;
        },
        handleEdit(index, item) {
            this.showDialog = true
            this.schoolInfo = item
        },
        updateSchoolInfo() {
            this.schoolInfo = null
        }
    }
}
</script>
<style lang="scss">
.school-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>