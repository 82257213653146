<template>
    <div class="user-list">
        <div class="tools-div">
            <el-button type="primary" @click="showAddBannerDialog = true">添加</el-button>
        </div>
        <div class="tab">
            <el-table :data="bannerList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="title" label="标题" width="180" align="center"></el-table-column>
                <el-table-column prop="description" label="描述" width="180" align="center"></el-table-column>
                <el-table-column label="图片" width="100" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.image" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column prop="router" label="路由" width="180" align="center"></el-table-column>
                <!-- <el-table-column prop="sort" label="排序" width="180"></el-table-column> -->
                <el-table-column prop="createTime" label="创建时间" width="220" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="220" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <AddBannerDialog :schoolid=schoolid :showDialog.sync="showAddBannerDialog" @cancelAddDialog="cancelAddDialog"
            @refreshList="getBanners" />
    </div>
</template>
<script>
import { getBannerList, removeBanner } from '@/http/api'
import AddBannerDialog from '@/components/dialogs/AddBannerDialog'
export default {
    name: 'UserList',
    components: {
        AddBannerDialog
    },
    data() {
        return {
            bannerList: [],
            showAddBannerDialog: false
        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getBanners(); // 调用接口请求
            }
        },
    },
    created() {
        if (this.schoolid !== '') {
            this.getBanners();
        }
    },
    methods: {
        getBanners() {
            getBannerList({
                schoolid: this.schoolid,
            }).then(res => {
                this.bannerList = res.data
            })
        },
        cancelAddDialog() {
            this.showAddBannerDialog = false;
        },
        handleDelete(index, item) {
            removeBanner({
                id: item.id
            }).then(res => {
                this.bannerList.splice(index, 1);
                this.$message.success('删除成功')
            })
        }
    }
}
</script>
<style lang="scss">
.user-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>