<template>
    <div class="page">
        <el-col :span="3">
            <el-select v-model="schoolValue" placeholder="请选择" @change="changeSelect">
                <el-option v-for="item in schools" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div style="padding: 15px 0;">
                <el-switch @change="switchChange" v-model="switchValue" active-text="需要审核" inactive-text="不需要审核">
                </el-switch>
            </div>
            <div style="padding: 15px 0;">
                <el-switch @change="switchAnonymityChange" v-model="switchAnonymityValue" active-text="开启匿名"
                    inactive-text="不开启匿名">
                </el-switch>
            </div>
            <el-menu class="el-menu-demo" default-active="user" @select="handleSelect">
                <el-menu-item index="user">
                    <i class="el-icon-menu"></i>
                    <span slot="title">用户列表</span>
                </el-menu-item>
                <el-menu-item index="banner">
                    <i class="el-icon-menu"></i>
                    <span slot="title">轮播图</span>
                </el-menu-item>
                <el-submenu index="news-info">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span>资讯</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="news">资讯列表</el-menu-item>
                        <el-menu-item index="news-review-all">资讯留言列表</el-menu-item>
                        <el-menu-item index="news-review">留言审核列表</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-submenu index="post-info">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span>帖子</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="tabbar">帖子类型</el-menu-item>
                        <el-menu-item index="post-all">帖子列表</el-menu-item>
                        <el-menu-item index="post-review-all">帖子评论列表</el-menu-item>
                        <el-menu-item index="post">帖子审核列表</el-menu-item>
                        <el-menu-item index="post-review">评论审核列表</el-menu-item>
                        <el-menu-item index="post-report">帖子举报列表</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item index="school">
                    <i class="el-icon-menu"></i>
                    <span slot="title">学校列表</span>
                </el-menu-item>
            </el-menu>
        </el-col>
        <div class="content">
            <component :is="currentComponent" />
        </div>
        <AddSchoolDialog :showDialog.sync="showDialog" @refreshList="getSchoolData" />
    </div>
</template>
<script>
import UserList from '@/components/UserList.vue';
import BannerList from '@/components/BannerList.vue';
import TabbarList from '@/components/TabbarList.vue';
import NewsList from '@/components/NewsList.vue';
import NewsReviewList from '@/components/NewsReviewList.vue';
import PostList from '@/components/PostList.vue';
import PostReviewList from '@/components/PostReviewList.vue';
import PostReportList from '@/components/PostReportList.vue';
import SchoolList from '@/components/SchoolList.vue';
import { getSchoolList, getIsCheck, editIsCheck, getIsAnonymity, editIsAnonymity } from '@/http/api'
import AddSchoolDialog from '@/components/dialogs/AddSchoolDialog.vue';
import PostListAll from '@/components/PostListAll.vue';
import PostReviewAllList from '@/components/PostReviewAllList.vue';
import NewsReviewAllList from '@/components/NewsReviewAllList.vue';


export default {
    components: {
        AddSchoolDialog
    },
    data() {
        return {
            currentView: 'user',
            schools: [],
            schoolValue: '',
            showDialog: false,
            switchValue: true,
            switchAnonymityValue: false,
        };
    },
    created() {
        this.getSchoolData()
        this.getCheckValue()
        this.getAnonymityValue()
    },
    computed: {
        // 根据 currentView 返回对应的组件
        currentComponent() {
            switch (this.currentView) {
                case 'user': return UserList;
                case 'banner': return BannerList;
                case 'tabbar': return TabbarList;
                case 'news': return NewsList;
                case 'news-review': return NewsReviewList;
                case 'post': return PostList;
                case 'post-review': return PostReviewList;
                case 'post-report': return PostReportList;
                case 'school': return SchoolList;
                case 'post-all': return PostListAll;
                case 'post-review-all': return PostReviewAllList;
                case 'news-review-all': return NewsReviewAllList;
                default: return null;
            }
        }
    },
    methods: {
        handleSelect(index) {
            this.currentView = index; // 切换当前视图
        },
        getSchoolData() {
            getSchoolList().then(res => {
                if (res.data.length === 0) {
                    this.showDialog = true
                } else {
                    this.schools = res.data.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    this.schoolValue = res.data[0].id
                    this.$store.commit('setSchoolId', this.schoolValue)
                }
            })
        },
        getCheckValue() {
            getIsCheck().then(res => {
                if (res.code === 200) {
                    this.switchValue = res.data === '1' ? true : false
                }
            })
        },

        switchChange(e) {
            editIsCheck(this.switchValue ? '1' : '0')
        },

        getAnonymityValue() {
            getIsAnonymity().then(res => {
                if (res.code === 200) {
                    this.switchAnonymityValue = res.data === '1' ? true : false
                }
            })
        },

        switchAnonymityChange(e) {
            editIsAnonymity(this.switchAnonymityValue ? '1' : '0')
        },

        changeSelect(e) {
            this.schoolValue = e
            this.$store.commit('setSchoolId', this.schoolValue)

        }
    }
}
</script>
<style lang="scss">
.page {
    display: flex;
    flex-direction: row;

    .content {
        margin-left: 30px;
    }
}
</style>