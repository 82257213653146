import Vue from 'vue'
import Vuex from 'vuex'

// 挂载Vuex
Vue.use(Vuex)

/**
 * vuex资料
 * https://www.jianshu.com/p/2e5973fe1223
 *
 * 存放的键值对就是所要管理的状态
 * state: {}
 *
 * getters中的方法有两个默认参数
 * state 当前VueX对象中的状态对象
 * getters 当前getters对象，用于将getters下的其他getter拿来用
 * getters: {}
 *
 * 操作state数据的方法的集合，比如对该数据的修改、增加、删除等
 * mutations
 */

export default new Vuex.Store({
  state: {
    token: '',
    schoolid: '',
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token); // 将 token 保存到 localStorage
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem('token'); // 清除 localStorage 中的 token
    },
    setSchoolId(state, schoolid) {
      state.schoolid = schoolid
    },
  },
  actions: {},
  modules: {}
})
