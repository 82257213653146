<template>
    <div class="news-list">
        <div class="tools-div">
            <el-button type="primary" @click="showDialog = true">添加</el-button>
        </div>
        <div class="tab">
            <el-table :data="dataList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="sort" label="排序" width="100" align="center"></el-table-column>
                <el-table-column prop="title" label="标题" width="180" align="center"></el-table-column>
                <el-table-column prop="childTitle" label="子标题" width="180" align="center"></el-table-column>
                <el-table-column label="文章封面" width="100" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.image" alt="" style="width: 50px; height: 50px;">
                    </template>
                </el-table-column>
                <el-table-column label="文章内容" width="100" align="center">
                    <template slot-scope="scope">
                        <p style="color: #409EFF;" @click="lookClick(scope.row)">点击查看</p>
                    </template>
                </el-table-column>
                <el-table-column prop="isRecommend" label="是否推荐" width="100" align="center">
                    <template slot-scope="scope">
                        <p style="color: #409EFF;">{{ scope.row.isRecommend === 1 ? '是' : '否' }}</p>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="likeNum" label="点赞数(真实)" width="120" align="center"></el-table-column> -->
                <el-table-column prop="likeNumber" label="点赞数(人工)" width="120" align="center"></el-table-column>
                <el-table-column prop="shareNum" label="分享数" width="100" align="center"></el-table-column>
                <el-table-column prop="lookNum" label="阅读数" width="100" align="center"></el-table-column>
                <el-table-column prop="reviewNum" label="留言数" width="100" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="220" align="center"></el-table-column>
                <!-- <el-table-column prop="updateTime" label="更新时间" width="220" align="center"></el-table-column> -->
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <AddNewsDialog @refreshList="getDatas" :schoolid=schoolid :showDialog.sync="showDialog" :newsInfo="newsInfo"
            @updateNewsInfo="updateNewsInfo" />
        <LookNewsDialog :lookShowDialog.sync="lookShowDialog" :newsInfo="lookNewsInfo" />
        <el-pagination background layout="prev, pager, next" :page-count="totalPage" @current-change="nextPgae">
        </el-pagination>
    </div>
</template>
<script>
import { getNewsList, removeNews } from '@/http/api'
import AddNewsDialog from '@/components/dialogs/AddNewsDialog'
import LookNewsDialog from '@/components/dialogs/LookNewsDialog'
export default {
    name: 'NewsList',
    components: {
        AddNewsDialog,
        LookNewsDialog
    },
    data() {
        return {
            dataList: [],
            showDialog: false,
            lookShowDialog: false,
            lookNewsInfo: null,
            newsInfo: null,
            pageSize: 10,
            pageIndex: 1,
            totalPage: 1
        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDatas(); // 调用接口请求
            }
        },
    },
    created() {

    },
    created() {
        if (this.schoolid !== '') {
            this.getDatas();
        }
    },
    methods: {
        getDatas() {
            getNewsList({
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                schoolid: this.schoolid,
            }).then(res => {
                this.totalPage = res.data.totalPage
                this.dataList = res.data.rows
            })
        },
        cancelAddDialog() {
            this.showDialog = false;
        },
        handleEdit(index, item) {
            this.showDialog = true
            this.newsInfo = item
        },
        updateNewsInfo() {
            this.newsInfo = null
        },
        nextPgae(e) {
            this.pageIndex = e;
            this.getDatas();
        },
        handleDelete(index, item) {
            removeNews({
                id: item.id
            }).then(res => {
                this.dataList.splice(index, 1);
                this.$message.success('删除成功')
            })
        },
        lookClick(item) {
            console.log(item);
            if (item.url !== null && item.url !== '' && item.url !== undefined) {
                window.open(item.url, '_blank')
            } else {
                this.lookShowDialog = true;
                this.lookNewsInfo = item;
            }

        }
    }
}
</script>
<style lang="scss">
.news-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>