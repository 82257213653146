<template>
    <div class="page">
        <img src="@/assets/login.png" />
        <input v-model="phone" type="text" placeholder="请输入账号" />
        <input v-model="password" type="password" placeholder="请输入密码" />
        <button @click="loginFun">登录</button>
    </div>
</template>
<script>
import { login } from '@/http/api'

export default {
    name: 'LoginView',
    components: {},
    data() {
        return {
            phone: '',
            password: '',
        }
    },
    created() {

    },
    methods: {
        loginFun: function () {
            if (this.phone === '') {
                return this.$message.error('请输入账号')
            }
            if (this.password === '') {
                return this.$message.error('请输入密码')
            }
            login({
                password: this.password,
                openid: this.phone
            }).then((res) => {
                // 存入但Vuex,但Vuex存储的数据在页面刷新后会被重置
                this.$store.commit('setToken', res.data)
                // 将 token 保存到 localStorage
                this.$router.push('/home')
            })
        }
    }
};
</script>
<style lang="scss" scoped>
.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;

    img {
        margin-top: 100px;
        width: 344px;
        height: 252px;
    }

    input {
        padding: 0 10px;
        margin-top: 10px;
        height: 30px;
    }

    button {
        margin-top: 20px;
        width: 100px;
    }
}
</style>