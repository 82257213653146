<template>
    <div class="page">
        <el-dialog title="资讯内容" :visible.sync="myShowDialog" :close-on-press-escape="false"
            :close-on-click-modal="false" :show-close="false">
            <div v-html="newsInfo?.content">
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="canncelClick">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>



export default {

    data() {
        return {
            myShowDialog: false,
        };
    },
    props: {
        lookShowDialog: {
            type: Boolean
        },
        // 当这个不为null时，表示编辑
        newsInfo: {
            type: Object
        }
    },
    watch: {
        lookShowDialog(newValue) {
            this.myShowDialog = newValue;
        },
    },

    methods: {
        canncelClick() {
            this.$emit('update:lookShowDialog', false);
        },

    }
}
</script>
<style lang="scss"></style>