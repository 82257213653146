<template>
    <div class="page">
        <el-dialog title="修改用户信息" :visible.sync="showEditUserDialog" :close-on-press-escape="false"
            :close-on-click-modal="false" :show-close="false">
            <el-form>
                <el-form-item label="昵称" :label-width="formLabelWidth">
                    <el-input v-model="title" placeholder="请输入昵称"></el-input>
                </el-form-item>
                <el-form-item label="头像" :label-width="formLabelWidth">
                    <el-upload class="avatar-uploader" :action="baseURL + '/api/common/upload'" :headers="uploadHeaders"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="canncelClick">取 消</el-button>
                <el-button type="primary" @click="editUserInfoClick">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { editUser } from '@/http/api'
import { baseURL } from '@/utils/const'
export default {
    data() {
        return {
            showEditUserDialog: false,
            formLabelWidth: '120px',
            title: '',
            imageUrl: '',
            baseURL: baseURL
        };
    },
    props: {
        showDialog: {
            type: Boolean
        },
        // 当这个不为null时，表示编辑
        userInfo: {
            type: Object
        }
    },
    watch: {
        // 监听 schoolid 变化
        showDialog(newVal, oldVal) {
            this.showEditUserDialog = newVal
        },
        userInfo(newVal, oldVal) {
            if (newVal !== null && newVal !== undefined) {
                this.title = newVal.name;
                this.imageUrl = newVal.avatar;
            }
        },
    },
    computed: {
        // 动态获取 headers
        uploadHeaders() {
            return {
                Authorization: `Bearer ${this.$store.state.token}`, // 从 Vuex 中动态获取 Token
            };
        },
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.imageUrl = res.data
        },
        beforeAvatarUpload(file) {
            return true;
        },
        editUserInfoClick() {
            this.showEditUserDialog = false;
            editUser({
                id: this.userInfo.id,
                name: this.title,
                avatar: this.imageUrl,
            }).then(res => {
                this.canncelClick()
                this.$emit('refreshList');
                this.$message.success('添加成功')
            })
        },
        canncelClick() {
            this.showEditUserDialog = false;
            this.$emit('cancelEditDialog');
        }
    }
}
</script>
<style lang="scss">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px !important;
    text-align: center;
}

.avatar {
    width: 78px;
    height: 78px;
    display: block;
}
</style>