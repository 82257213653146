<template>
    <div class="news-review-list">
        <div class="tools-div">
            <el-button type="primary" @click="showDialog = true">添加</el-button>
        </div>
        <div class="tab">
            <el-table :data="dataList" stripe border style="width: 100%">
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="title" label="资讯标题" width="180" align="center"></el-table-column>
                <el-table-column prop="name" label="留言者" width="150" align="center"></el-table-column>
                <el-table-column prop="content" label="内容" width="220" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="220" align="center"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">通过</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination background layout="prev, pager, next" :page-count="totalPage" @current-change="nextPgae">
        </el-pagination>
    </div>
</template>
<script>
import { getNewsReviewList, reviewEdit } from '@/http/api'
export default {
    name: 'NewsReviewList',
    components: {
    },
    data() {
        return {
            dataList: [],
            pageSize: 10,
            pageIndex: 1,
            totalPage: 1
        }
    },
    computed: {
        schoolid() {
            return this.$store.state.schoolid
        },
    },
    watch: {
        // 监听 schoolid 变化
        schoolid(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getDatas(); // 调用接口请求
            }
        },
    },
    created() {

    },
    created() {
        if (this.schoolid !== '') {
            this.getDatas();
        }
    },
    methods: {
        getDatas() {
            getNewsReviewList({
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                schoolid: this.schoolid,
            }).then(res => {
                this.totalPage = res.data.totalPage
                this.dataList = res.data.rows
            })
        },
        nextPgae(e) {
            this.pageIndex = e;
            this.getDatas();
        },
        handleEdit(index, item) {
            reviewEdit({
                id: item.id,
                status: 1
            }).then(res => {
                this.dataList.splice(index, 1);
                this.$message.success('审核通过')
            })
        },
        handleDelete(index, item) {
            reviewEdit({
                id: item.id,
                status: 2
            }).then(res => {
                this.dataList.splice(index, 1);
                this.$message.success('审核拒绝')
            })
        },
    }
}
</script>
<style lang="scss">
.news-review-list {
    display: flex;
    flex-direction: column;

    .tools-div {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .search-input {
            width: 200px;
            margin-right: 10px;
        }
    }

    .tab {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>